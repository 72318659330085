import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export function PortraitImage() {
  return (
    <StaticImage
      src="../images/sibylleeckert-portrait.jpg"
      alt="Sibylle Eckert"
      placeholder="background"
      layout="constrained"
    />
  )
}

export default PortraitImage