import React from "react"
import { graphql } from "gatsby"

import {PortraitImage} from "../components/image"

import Layout from "../components/layout"

import styled, { ThemeProvider } from "styled-components";

const ImageContainer = styled.div`
  margin: 10% 15%;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 20%;
`;


const AboutPage = (props) => (
  <Layout pageTitle="Sporttherapie" buttonLink="/contact/" buttonTitle="Kontakt">
    <ImageContainer>
      <PortraitImage />
    </ImageContainer>
    <TextContainer>
      <h2 class="highlight-text">Mein Ziel</h2>
      <p>Den Menschen durch Sport und Therapie ein Bewusstsein für ihren Körper zu kreieren und Ihnen verhelfen ihre Ziele zu erreichen.</p>
      <p>Im Bereich des Spitzensportes,  Breitensports oder auch nach Verletzungen.</p>
      <p class="action-text"><a href="/terminbuchung/">Termin Buchen</a></p>
    </TextContainer>
  </Layout>
)

export default AboutPage
